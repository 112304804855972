import { Dashboard, syncTranslations, type DashboardData } from '@driscollsinc/ggs-dashboard-module-ui';
import { withOktaAuth } from '@okta/okta-react';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'routes/withRouter';
import { setPageTitleAction, showToast } from 'actions/actions';
import { setSettlementWorkbenchData } from 'actions/settlementWorkbenchAction';
import DrcWrapper from 'components/DrcWrapper';
import useDashboard from 'hooks/v2/useDashboard';
import { format } from 'date-fns';
import Stepper from 'components/Stepper';
import MasterDataUtilities from 'data/MasterDataUtilities';
import { getLanguage } from 'utils/helper';
import useLang from 'hooks/v2/useLang';
import { NAMESPACE } from 'i18n';
import useGAAnalytics from 'hooks/v2/useGAAnalytics';
import { EventConstants } from 'data/EventConstants';

function DashboardPage(props) {
    const { getDashboardData, syncOracle, syncError } = useDashboard(props.oktaAuth);
    const { logEvent, logError } = useGAAnalytics();
    const language = localStorage.getItem('GGS_UserLang');
    const { getTextTranslated } = useLang();

    useEffect(() => {
        syncTranslations(getLanguage(language));
    }, [language]);

    useEffect(() => {
        if (syncError) {
            props.showToast(getTextTranslated('Sync Failure', NAMESPACE.DASHBOARD), false);
        }
    }, [syncError]);

    if (!MasterDataUtilities.Check(props.isMasterDataInitialized)) {
        return MasterDataUtilities.Redirect();
    }

    const getData = async (poolweek: string): Promise<DashboardData> => {
        const { Information, Exception } = await getDashboardData(poolweek).then((data) => data);
        const exceptionGroup = Exception.reduce((prev, curr) => {
            return {
                ...prev,
                [curr.Label]: [
                    ...(prev?.[curr.Label] ?? []),
                    { value: parseFloat(curr.DataValue?.replace(/,/g, '') ?? 0), displayValue: curr.DataValue, label: curr.DataType }
                ]
            };
        }, {});

        return {
            LastUpdatedDate: format(new Date(), 'PP'),
            Count: Information.map((info) => ({
                label: info.Label,
                value: parseFloat(info.DataValue?.replace(/,/g, '') ?? 0),
                displayValue: info.DataValue ?? '0.00',
                data: {
                    poolweek: poolweek,
                    type: 'Information',
                    datatype: [info.DataType]
                }
            })),
            Exceptions: Object.keys(exceptionGroup).map((key) => ({
                label: key,
                ...(exceptionGroup[key].length === 1
                    ? { value: exceptionGroup[key][0].value, displayValue: exceptionGroup[key][0].displayValue }
                    : { values: exceptionGroup[key] }),
                data: {
                    poolweek: poolweek,
                    datatype: exceptionGroup[key].map((exception) => exception.label),
                    type: 'Exception'
                }
            }))
        };
    };

    return (
        <DrcWrapper>
            <Stepper page={'Dashboard'} showPageTitle={false}></Stepper>
            <Dashboard
                defaultWeek={props.currentPoolWeek.length ? props.currentPoolWeek[0].PoolWeek : 0}
                availableWeeks={props.yearPoolWeeks.map((yr) => ({
                    week: yr.PoolWeek,
                    startDate: yr.PoolWeekStartDate,
                    endDate: yr.PoolWeekEndDate
                }))}
                getDashboardData={getData}
                routeFormat={(exception) =>
                    `./Exceptions?poolweek=${exception.data.poolweek}&datatype=${decodeURIComponent(exception.label)}&label=${exception.data.datatype.join(',')}&Type=${
                        exception.data.type
                    }`
                }
                onSync={async (entities) => {
                    await syncOracle(entities);
                    logEvent(EventConstants.ORACLE_SYNC, { time: new Date().toISOString() });
                }}
            />
        </DrcWrapper>
    );
}

const mapStateToProps = ({ masterReducer, settlementWorkbenchReducer, rootReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    total: settlementWorkbenchReducer.totalRecords,
    pageTitle: rootReducer.pageTitle,
    records: settlementWorkbenchReducer.records,
    currentPoolWeek: masterReducer.currentPoolWeek,
    yearPoolWeeks: masterReducer.yearPoolWeeks,
    showToast: rootReducer.showToast
});

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showToast: (message, type) => dispatch(showToast(message, type)),
    setSettlementWorkbenchData: (data) => dispatch(setSettlementWorkbenchData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withRouter(DashboardPage)));
