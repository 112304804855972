import { DashboardData } from '@driscollsinc/ggs-dashboard-module-ui';
import DuDateUtilities from 'drc/driscolls-react-components/Utilities/DuDateUtilities';
import { NAMESPACE } from 'i18n';
import { useState } from 'react';
import { GET_DASHBOARD_DATA, GET_ELIGIBLE_POOLWEEK_DATA, GET_EXCEPTION_DATA, SYNC_ORACLE } from 'services/v2/DashboardApi';
import { getCall, postCall } from 'services/v2/Services';
import useGetApiTokens from './useGetApiTokens';
import useLang from './useLang';

const useDashboard = (oktaAuth: any) => {
    const [syncError, setSyncError] = useState(null);
    const { getTokenAndId } = useGetApiTokens();
    const { getTextTranslated } = useLang();
    const dateFields = ['ModifiedDateTime', 'CreatedDateTime', 'DocDate'];
    const formatData = (data: any) => {
        const result = (data.Data || []).map((item) => {
            Object.keys(item).map((key) => (dateFields.includes(key) ? (item[key] = DuDateUtilities.FormatDateTimeFromIso(item[key])) : ''));
            return item;
        });
        return result;
    };

    const sortColumns = (data: any) => {
        const result = data
            .sort((item1: any, item2: any) => {
                return item1.Position - item2.Position;
            })
            .map((item) => {
                return { ...item, accessorKey: item.Name, header: getTextTranslated(item.Name, NAMESPACE.DASHBOARD) };
            });
        return result;
    };

    const getDashboardData = async (poolweek: string): Promise<DashboardData> => {
        const { token } = await getTokenAndId(oktaAuth);
        const response = await getCall(GET_DASHBOARD_DATA({ poolweek }), token, {});
        return response.raw.data;
    };

    const getExceptions = async (params: { [key: string]: any }, searchString: string) => {
        const { token } = await getTokenAndId(oktaAuth);
        const response = await getCall(GET_EXCEPTION_DATA({ ...params, filter: searchString }), token, {});
        return {
            columns: sortColumns(response.raw.data.Data.Label || []),
            data: formatData(response.raw.data.Data) ?? [],
            totalItems: response.raw.data.TotalCount ?? 0
        };
    };

    const getEligiblePoolweek = async (params: { poolweek: string }) => {
        const { token } = await getTokenAndId(oktaAuth);
        const response = await getCall(GET_ELIGIBLE_POOLWEEK_DATA(params), token, {});
        return response.display.Data && response.display.Data.length > 0 ? response.display.Data[0] : {};
    };

    const syncOracle = async (entities: string[]) => {
        const { token, userEmail } = await getTokenAndId(oktaAuth);
        try {
            const response = await postCall(
                SYNC_ORACLE(),
                token,
                {},
                {
                    EntityType: entities,
                    RequestBy: userEmail
                }
            );
            if (response && response.errors && response.errors.length > 0) {
                throw response.errors[0];
            }
            return response;
        } catch (err) {
            setSyncError(err);
        } finally {
            return null;
        }
    };

    return { getDashboardData, getExceptions, getEligiblePoolweek, syncOracle, syncError };
};
export default useDashboard;
