import { Call } from '@driscollsinc/driscolls-display-rules';
import { APPROVER_GROUP_ID } from 'data/constants';
import { useState } from 'react';
import APIEndPoints from 'services/api';
import {
    GET_BUSINESS_RULES_FOR_APPROVER,
    GET_BUSINESS_RULE_TYPES,
    GET_BUSINESS_RULES,
    GET_BUSINESS_RULES_HISTORY,
    GET_LOOKUP_DATA,
    GET_RULES_DETAILS,
    POST_SUBMIT_RULE,
    POST_ASSIGNABLE_LIST,
    GET_ACTIVE_PRICING_POOLS
} from 'services/v2/BusinessRulesAPI';
import { getCall, postCall, SUCCESS_STATUS_CODE } from 'services/v2/Services';
import useGetApiTokens from './useGetApiTokens';
import { userBusinessUnit } from 'utils/helper';
import useGAAnalytics from './useGAAnalytics';
import { EventConstants } from 'data/EventConstants';
import { IBusinesRuleType } from 'types/BusinesRuleType';
import { businssRulePayload } from 'data/BusinessRules';

const LOOKUP_TYPE_BRAND: string = 'Brand';
const LOOKUP_TYPE_BERRYTYPE: string = 'BerryType';
const LOOKUP_TYPE_FIELDTYPE: string = 'FieldType';

const useBusinessRules = (oktaAuth: any) => {
    const { logEvent, logError } = useGAAnalytics();
    const { getTokenAndId } = useGetApiTokens();
    const [typesError, setTypesError] = useState(null);
    const [typesLoading, setTypesLoading] = useState(false);
    const [businessRuleTypes, setBusinessRuleTypes] = useState([] as IBusinesRuleType[]);
    const [rulesError, setRulesError] = useState(null);
    const [rulesLoading, setRulesLoading] = useState(false);
    const [rulesForApproverLoading, setRulesForApproverLoading] = useState(false);
    const [businessRules, setBusinessRules] = useState([] as any[]);
    const [businessRulesListForApprover, setBusinessRulesListForApprover] = useState([] as any[]);
    const [businessRulesHistory, setBusinessRulesHistory] = useState([] as any[]);
    const [businessRulesHistoryTotalCount, setBusinessRulesHistoryTotalCount] = useState(0);
    const [businessRulesTotalCount, setBusinessRulesTotalCount] = useState(0);
    const [ruleDetailsError, setRuleDetailsError] = useState(null);
    const [ruleDetailsLoading, setRuleDetailsLoading] = useState(false);
    const [ruleDetails, setRuleDetails] = useState('');
    const [lookupDataLoading, setLookupDataLoading] = useState(false);
    const [lookupData, setLookupData] = useState([]);

    const [ruleCreateError, setRuleCreateError] = useState(null);
    const [ruleCreateLoading, setRuleCreateLoading] = useState(false);
    const [ruleCreateResponse, setRuleCreateResponse] = useState('');
    const [submitForApprovalResponse, setSubmitForApprovalResponse] = useState('');
    const [approverList, setApproverList] = useState(null);
    const [approverGroupId, setApproverGroupId] = useState('');
    const [responseTime, setResponseTime] = useState<number>(0);

    const getBusinessRuleTypes = async () => {
        setTypesLoading(true);
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const response = await getCall(GET_BUSINESS_RULE_TYPES(), token, {});
            if (response && response.errors && response.errors.length > 0) {
                throw response.errors[0];
            } else {
                setBusinessRuleTypes(response.display.Data);
            }
        } catch (err) {
            setTypesError(err);
        } finally {
            setTypesLoading(false);
        }
    };

    const getPricingPoolList = async () => {
        setRulesLoading(true);
        const { token } = await getTokenAndId(oktaAuth);
        const startTime = performance.now();
        try {
            const response = await getCall(GET_ACTIVE_PRICING_POOLS(), token, {});
            const endTime = performance.now();
            if (response && response.errors && response.errors.length > 0) {
                throw response.errors[0];
            } else {
                setBusinessRules(response.display.Data);
                setResponseTime((endTime - startTime) / 1000);
            }
        } catch (err) {
            setRulesError(err);
        } finally {
            setRulesLoading(false);
        }
    };

    const getBusinessRules = async (ruletypeid: string, pagesize: number, startpage: number, filter: string = '') => {
        setRulesLoading(true);
        const { token } = await getTokenAndId(oktaAuth);
        const startTime = performance.now();
        try {
            const response = await getCall(GET_BUSINESS_RULES(ruletypeid, pagesize, startpage, filter), token, {});
            const endTime = performance.now();
            if (response && response.errors && response.errors.length > 0) {
                throw response.errors[0];
            } else {
                setBusinessRules(response.display.Data);
                setBusinessRulesTotalCount(response.display.TotalCount);
                setRuleCreateResponse(null);
                setResponseTime((endTime - startTime) / 1000);
            }
        } catch (err) {
            setRulesError(err);
        } finally {
            setRulesLoading(false);
        }
    };
    const getBusinessRulesListForApprovers = async (ruletypeid: string, pagesize: number, startpage: number, filter: string = '') => {
        setRulesForApproverLoading(true);

        const startTime = performance.now();
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const response = await getCall(GET_BUSINESS_RULES_FOR_APPROVER(ruletypeid, pagesize, startpage, filter), token, {});
            const endTime = performance.now();
            if (response && response.errors && response.errors.length > 0) {
                throw response.errors[0];
            } else {
                setBusinessRulesListForApprover(response.display.Data);
                setBusinessRulesTotalCount(response.display.TotalCount);
                setResponseTime((endTime - startTime) / 1000);
            }
        } catch (err) {
            setRulesError(err);
        } finally {
            setRulesForApproverLoading(false);
        }
    };

    const getBusinessRulesHistory = async (
        ruletypeid: string,
        pagesize: number,
        startpage: number,
        entity: string='RuleDetails',
        sortKey: string,
        sortOrder: string = 'DESC',
        filter: string = ''
    ) => {
        setRulesLoading(true);
        const startTime = performance.now();
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const response = await getCall(GET_BUSINESS_RULES_HISTORY(ruletypeid, pagesize, startpage, encodeURI(entity),sortOrder,sortKey,filter), token, {});
            const endTime = performance.now();
            if (response && response.errors && response.errors.length > 0) {
                throw response.errors[0];
            } else {
                if (response?.display?.Status === -1) {
                    setRulesError(response?.display?.Message);
                }
                setBusinessRulesHistory(response.display.ResponseData);
                setBusinessRulesHistoryTotalCount(response.display.TotalCount);
                setResponseTime((endTime - startTime) / 1000);
            }
        } catch (err) {
            setRulesError(err);
        } finally {
            setRulesLoading(false);
        }
    };

    const getRuleDetails = async (ruleid: string) => {
        setRuleDetailsLoading(true);
        const startTime = performance.now();
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const response = await getCall(GET_RULES_DETAILS(ruleid), token, {});
            const endTime = performance.now();
            if (response && response.errors && response.errors.length > 0) {
                throw response.errors[0];
            } else {
                setRuleDetails(response.display.ResponseData);
                setResponseTime((endTime - startTime) / 1000);
            }
        } catch (err) {
            setRuleDetailsError(err);
        } finally {
            setRuleDetailsLoading(false);
        }
    };

    const submitBulkRules = async (excelRecords: any[]) => {
        const { userEmail } = await getTokenAndId(oktaAuth);
        const bu = userBusinessUnit();
        excelRecords.forEach(async (item) => {
            const payload = businssRulePayload(item, userBusinessUnit(), userEmail);
            await submitRule(payload);
        });
    };

    const submitRule = async (payload: any) => {
        if (ruleCreateLoading) {
            return;
        }
        setRuleCreateLoading(true);
        // Reseting to null on new call
        setRuleCreateError(null);
        setRuleCreateResponse(null);
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const response = await postCall(POST_SUBMIT_RULE(), token, {}, payload);
            if (response && response.errors && response.errors.length > 0) {
                throw response.errors[0];
            } else if (response.display.Message && response.display.Status !== -1 && response.raw.status === SUCCESS_STATUS_CODE) {
                setRuleCreateResponse(response.display.Message);
                if (payload.GroupId) {
                    setSubmitForApprovalResponse(response.display.Message);
                }
            } else {
                throw response?.display?.Message || 'Something went wrong';
            }
        } catch (err) {
            setRuleCreateError(err);
            logError(EventConstants.BUSINES_RULE_SAVE_EXCEPTION, err);
        } finally {
            setRuleCreateLoading(false);
        }
    };

    const approveOrRejectBusinessRule = async (payload: any) => {
        if (ruleCreateLoading) {
            return;
        }
        setRuleCreateLoading(true);
        // Reseting to null on new call
        setRuleCreateError(null);
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const response = await postCall(POST_SUBMIT_RULE(), token, {}, payload);
            if (response && response.errors && response.errors.length > 0) {
                throw response.errors[0];
            } else if (response.display.Message && response.display.Status !== -1 && response.raw.status === SUCCESS_STATUS_CODE) {
                // setRuleCreateResponse(response.display.Message);
                //TODO: Here the API returns null message when success, once the fix is available this code will change
                return response.message === null ? 'Success' : 'error';
            } else {
                throw response?.display?.Message || 'Something went wrong';
            }
        } catch (err) {
            throw err || 'Something went wrong';
            return err;
        } finally {
            setRuleCreateLoading(false);
        }
    };

    const getApprovers = async () => {
        try {
            const { token } = await getTokenAndId(oktaAuth);
            let approverGroup = Object.keys(APPROVER_GROUP_ID).find(
                (group) => window.config.OKTA_GGS_ADMIN_GROUPS.includes(group) && group.indexOf(userBusinessUnit()) > -1
            );
            const groupId = APPROVER_GROUP_ID[approverGroup];
            setApproverGroupId(groupId);
            let response = await Call(
                {
                    name: 'ApproverUserList',
                    url: APIEndPoints.GET_APPROVER_LIST(APPROVER_GROUP_ID[approverGroup]),
                    method: 'GET',
                    options: {}
                },
                token
            );
            setApproverList(
                (response.raw.data || [])
                    .filter((user) => user.status === 'ACTIVE')
                    .map((usr) => ({
                        label: ` ${usr.profile.firstName} ${usr.profile.lastName} (${usr.profile.email})`,
                        value: usr.profile.email
                    }))
            );
        } catch (err) {}
    };

    const getLookupData = async (lookupType: string, enabledFlag: number = 1, startPage: number = 1, pageSize: number = 1) => {
        setLookupDataLoading(true);
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const response = await getCall(GET_LOOKUP_DATA(lookupType, userBusinessUnit()), token, {});
            if (response && response.errors && response.errors.length > 0) {
                throw response.errors[0];
            } else {
                const data = response.display.Data;
                setLookupData((prev) => {
                    return {
                        ...prev,
                        ...(lookupType === LOOKUP_TYPE_BRAND && {
                            Brand: data.map((item) => {
                                return { value: item.LookupCode || '', label: item.LookupCode };
                            })
                        }),
                        ...(lookupType === LOOKUP_TYPE_BERRYTYPE && {
                            BerryType: data.map((item) => {
                                return { value: item.LookupCode || '', label: item.LookupCode };
                            })
                        }),
                        ...(lookupType === LOOKUP_TYPE_FIELDTYPE && {
                            FieldType: data.map((item) => {
                                return { value: item.LookupCode || '', label: item.LookupCode };
                            })
                        })
                    };
                });
            }
        } catch (err) {
            setRuleDetailsError(err);
        } finally {
            setLookupDataLoading(false);
        }
    };

    return {
        getPricingPoolList,
        businessRuleTypes,
        typesError,
        typesLoading,
        getBusinessRuleTypes,
        getBusinessRules,
        getBusinessRulesListForApprovers,
        getBusinessRulesHistory,
        businessRulesHistoryTotalCount,
        businessRulesHistory,
        rulesError,
        rulesLoading,
        rulesForApproverLoading,
        businessRules,
        businessRulesListForApprover,
        businessRulesTotalCount,
        getRuleDetails,
        ruleDetailsError,
        ruleDetailsLoading,
        ruleDetails,
        submitRule,
        ruleCreateResponse,
        ruleCreateError,
        ruleCreateLoading,
        responseTime,
        getApprovers,
        approverList,
        getLookupData,
        submitForApprovalResponse,
        lookupData,
        lookupDataLoading,
        approverGroupId,
        approveOrRejectBusinessRule,
        LOOKUP_TYPE_BERRYTYPE,
        LOOKUP_TYPE_BRAND,
        LOOKUP_TYPE_FIELDTYPE,
        submitBulkRules
    };
};
export default useBusinessRules;
